const data = [
  {
    id: 0,
    num: 367341611,
    reference: 'f5a1caee-125f-4e4c-9805-14afc167c287',
    balance: '$1,285.39',
    file_name: 'VIOCULAR',
    file_url: 'http://placehold.it/32x32',
    amount: '112732895 XAF',
    open_date: '12-02-09 10:00:00',
    state: 1,
    close_date: '22/09/2022 10:10:00',
    type_depot: 'Virement',
    moyen_paiement: 'AFRILAND FIRST BANK',
    account_debite: 'HJ67HQSDHA9831JH',
    account_credite: 'AIUEQDQDAIQDOOIP',
  },
  {
    id: 1,
    num: 868717209,
    reference: '380d33fb-ff4e-441a-bffc-9979af293103',
    balance: '$1,285.39',
    file_name: 'GEOFORMA',
    file_url: 'http://placehold.it/32x32',
    amount: '536344663 XAF',
    open_date: '12-02-09 10:00:00',
    state: 0,
    close_date: '',
    type_depot: 'Virement',
    moyen_paiement: 'AFRILAND FIRST BANK',
    account_debite: 'HJ67HQSDHA9831JH',
    account_credite: 'AIUEQDQDAIQDOOIP',
  },
  {
    id: 2,
    num: 877248836,
    reference: '0a7451e7-afca-4265-b0c3-fa29156ebb25',
    balance: '$1,285.39',
    file_name: 'ELEMANTRA',
    file_url: 'http://placehold.it/32x32',
    amount: '511287059 XAF',
    open_date: '12-02-09 10:00:00',
    state: 0,
    close_date: '',
    type_depot: 'Virement',
    moyen_paiement: 'AFRILAND FIRST BANK',
    account_debite: 'HJ67HQSDHA9831JH',
    account_credite: 'AIUEQDQDAIQDOOIP',
  },
  {
    id: 3,
    num: 218440392,
    reference: '684a1030-0a13-4b17-8825-2ea7532335a6',
    balance: '$2,307.26',
    file_name: 'KEEG',
    file_url: 'http://placehold.it/32x32',
    amount: '316278465 XAF',
    open_date: '12-02-09 10:00:00',
    state: 1,
    close_date: '22/09/2022 10:10:00',
    type_depot: 'Versement',
    moyen_paiement: 'Orange Money',
    account_debite: '',
    account_credite: '',
  },
  {
    id: 4,
    num: 796573180,
    reference: '78d0ef7f-38f0-45bf-a099-6475d4a8e6e4',
    balance: '$1,381.36',
    file_name: 'MIRACLIS',
    file_url: 'http://placehold.it/32x32',
    amount: '473853392 XAF',
    open_date: '12-02-09 10:00:00',
    state: 0,
    close_date: '',
    type_depot: 'Virement',
    moyen_paiement: 'AFRILAND FIRST BANK',
    account_debite: 'HJ67HQSDHA9831JH',
    account_credite: 'AIUEQDQDAIQDOOIP',
  },
  {
    id: 5,
    num: 500466568,
    reference: '01c6d1e0-6a06-4fa3-8457-82939133f84f',
    balance: '$3,196.32',
    file_name: 'XINWARE',
    file_url: 'http://placehold.it/32x32',
    amount: '975741349 XAF',
    open_date: '12-02-09 10:00:00',
    state: 1,
    close_date: '22/09/2022 10:10:00',
    type_depot: 'Versement',
    moyen_paiement: 'Orange Money',
    account_debite: '',
    account_credite: '',
  },
  {
    id: 6,
    num: 548087466,
    reference: 'c8d4b8ff-8068-4714-8850-7d70a6e3f24c',
    balance: '$1,305.66',
    file_name: 'ESCENTA',
    file_url: 'http://placehold.it/32x32',
    amount: '393215595 XAF',
    open_date: '12-02-09 10:00:00',
    state: 0,
    close_date: '',
    type_depot: 'Virement',
    moyen_paiement: 'AFRILAND FIRST BANK',
    account_debite: 'HJ67HQSDHA9831JH',
    account_credite: 'AIUEQDQDAIQDOOIP',
  },
  {
    id: 7,
    num: 487767753,
    reference: '2132441c-9be2-448b-8089-c832efb484a8',
    balance: '$3,804.51',
    file_name: 'INSURETY',
    file_url: 'http://placehold.it/32x32',
    amount: '551626932 XAF',
    open_date: '12-02-09 10:00:00',
    state: 1,
    close_date: '22/09/2022 10:10:00',
    type_depot: 'Versement',
    moyen_paiement: 'Orange Money',
    account_debite: '',
    account_credite: '',
  },
  {
    id: 8,
    num: 896783943,
    reference: '669ab587-66a6-4824-afce-aa3e223fcf73',
    balance: '$1,409.24',
    file_name: 'ZENCO',
    file_url: 'http://placehold.it/32x32',
    amount: '867300744 XAF',
    open_date: '12-02-09 10:00:00',
    state: 0,
    close_date: '',
    type_depot: 'Versement',
    moyen_paiement: 'Orange Money',
    account_debite: '',
    account_credite: '',
  },
  {
    id: 9,
    num: 145671898,
    reference: 'e46fa489-51fe-4bb4-8b0f-43b24e14350c',
    balance: '$2,838.02',
    file_name: 'SNACKTION',
    file_url: 'http://placehold.it/32x32',
    amount: '95165144 XAF',
    open_date: '12-02-09 10:00:00',
    state: 1,
    close_date: '22/09/2022 10:10:00',
    type_depot: 'Virement',
    moyen_paiement: 'AFRILAND FIRST BANK',
    account_debite: 'HJ67HQSDHA9831JH',
    account_credite: 'AIUEQDQDAIQDOOIP',
  },
  {
    id: 10,
    num: 336490592,
    reference: '273207d8-aea8-4b6b-b402-25bfd1877c1c',
    balance: '$1,480.06',
    file_name: 'DAIDO',
    file_url: 'http://placehold.it/32x32',
    amount: '527891345 XAF',
    open_date: '12-02-09 10:00:00',
    state: 1,
    close_date: '22/09/2022 10:10:00',
    type_depot: 'Virement',
    moyen_paiement: 'AFRILAND FIRST BANK',
    account_debite: 'HJ67HQSDHA9831JH',
    account_credite: 'AIUEQDQDAIQDOOIP',
  },
  {
    id: 11,
    num: 926321194,
    reference: 'd76c60fa-f389-49d7-b575-2fea89f4652d',
    balance: '$2,036.29',
    file_name: 'OPPORTECH',
    file_url: 'http://placehold.it/32x32',
    amount: '86972688 XAF',
    open_date: '12-02-09 10:00:00',
    state: 1,
    close_date: '22/09/2022 10:10:00',
    type_depot: 'Versement',
    moyen_paiement: 'Orange Money',
    account_debite: '',
    account_credite: '',
  },
  {
    id: 12,
    num: 570912600,
    reference: '66e83256-3d3d-4d73-8689-6a5b693753d0',
    balance: '$1,197.95',
    file_name: 'BRISTO',
    file_url: 'http://placehold.it/32x32',
    amount: '225616458 XAF',
    open_date: '12-02-09 10:00:00',
    state: 0,
    close_date: '',
    type_depot: 'Versement',
    moyen_paiement: 'Orange Money',
    account_debite: '',
    account_credite: '',
  },
  {
    id: 13,
    num: 255147423,
    reference: 'f1fa41c3-245c-4a5e-8abe-bf91f0db8498',
    balance: '$3,513.41',
    file_name: 'MANGLO',
    file_url: 'http://placehold.it/32x32',
    amount: '435456742 XAF',
    open_date: '12-02-09 10:00:00',
    state: 1,
    close_date: '22/09/2022 10:10:00',
    type_depot: 'Virement',
    moyen_paiement: 'AFRILAND FIRST BANK',
    account_debite: 'HJ67HQSDHA9831JH',
    account_credite: 'AIUEQDQDAIQDOOIP',
  },
  {
    id: 14,
    num: 137530256,
    reference: '04c5e675-805d-4224-a62c-b643a75afcd2',
    balance: '$1,215.79',
    file_name: 'TURNLING',
    file_url: 'http://placehold.it/32x32',
    amount: '746362329 XAF',
    open_date: '12-02-09 10:00:00',
    state: 1,
    close_date: '22/09/2022 10:10:00',
    type_depot: 'Versement',
    moyen_paiement: 'Orange Money',
    account_debite: '',
    account_credite: '',
  },
  {
    id: 15,
    num: 769918146,
    reference: '0a473f9f-a440-42f3-bbb1-35356d5650fd',
    balance: '$1,465.42',
    file_name: 'POLARAX',
    file_url: 'http://placehold.it/32x32',
    amount: '439277098 XAF',
    open_date: '12-02-09 10:00:00',
    state: 0,
    close_date: '',
    type_depot: 'Virement',
    moyen_paiement: 'AFRILAND FIRST BANK',
    account_debite: 'HJ67HQSDHA9831JH',
    account_credite: 'AIUEQDQDAIQDOOIP',
  },
  {
    id: 16,
    num: 623769833,
    reference: '738f7fd6-1ff2-46df-a07e-8ccfefcc0e91',
    balance: '$1,726.69',
    file_name: 'FLEXIGEN',
    file_url: 'http://placehold.it/32x32',
    amount: '238202317 XAF',
    open_date: '12-02-09 10:00:00',
    state: 1,
    close_date: '22/09/2022 10:10:00',
    type_depot: 'Versement',
    moyen_paiement: 'Orange Money',
    account_debite: '',
    account_credite: '',
  },
  {
    id: 17,
    num: 889110470,
    reference: '86654236-58a2-4646-90f0-a61cf7daa005',
    balance: '$1,640.84',
    file_name: 'MAKINGWAY',
    file_url: 'http://placehold.it/32x32',
    amount: '676147826 XAF',
    open_date: '12-02-09 10:00:00',
    state: 0,
    close_date: '',
    type_depot: 'Virement',
    moyen_paiement: 'AFRILAND FIRST BANK',
    account_debite: 'HJ67HQSDHA9831JH',
    account_credite: 'AIUEQDQDAIQDOOIP',
  },
  {
    id: 18,
    num: 382606895,
    reference: '7e544485-5ccd-4a68-a9fa-d62b6e7dbf8e',
    balance: '$3,219.91',
    file_name: 'KNOWLYSIS',
    file_url: 'http://placehold.it/32x32',
    amount: '711808709 XAF',
    open_date: '12-02-09 10:00:00',
    state: 1,
    close_date: '22/09/2022 10:10:00',
    type_depot: 'Virement',
    moyen_paiement: 'AFRILAND FIRST BANK',
    account_debite: 'HJ67HQSDHA9831JH',
    account_credite: 'AIUEQDQDAIQDOOIP',
  },
  {
    id: 19,
    num: 109225816,
    reference: '941a5419-b5f9-48b6-8a33-a425fa5c6c60',
    balance: '$2,705.47',
    file_name: 'STROZEN',
    file_url: 'http://placehold.it/32x32',
    amount: '423989957 XAF',
    open_date: '12-02-09 10:00:00',
    state: 0,
    close_date: '',
    type_depot: 'Virement',
    moyen_paiement: 'AFRILAND FIRST BANK',
    account_debite: 'HJ67HQSDHA9831JH',
    account_credite: 'AIUEQDQDAIQDOOIP',
  },
  {
    id: 20,
    num: 659289492,
    reference: 'f54e4015-62d8-4291-aa85-485b5c856c42',
    balance: '$3,407.32',
    file_name: 'ZOXY',
    file_url: 'http://placehold.it/32x32',
    amount: '137547312 XAF',
    open_date: '12-02-09 10:00:00',
    state: 1,
    close_date: '22/09/2022 10:10:00',
    type_depot: 'Virement',
    moyen_paiement: 'AFRILAND FIRST BANK',
    account_debite: 'HJ67HQSDHA9831JH',
    account_credite: 'AIUEQDQDAIQDOOIP',
  },
  {
    id: 21,
    num: 457932644,
    reference: 'e173742e-793d-4fea-b31b-f54fbfc8b710',
    balance: '$1,696.54',
    file_name: 'VERTIDE',
    file_url: 'http://placehold.it/32x32',
    amount: '987386441 XAF',
    open_date: '12-02-09 10:00:00',
    state: 1,
    close_date: '22/09/2022 10:10:00',
    type_depot: 'Virement',
    moyen_paiement: 'AFRILAND FIRST BANK',
    account_debite: 'HJ67HQSDHA9831JH',
    account_credite: 'AIUEQDQDAIQDOOIP',
  },
  {
    id: 22,
    num: 888004686,
    reference: '4719bf34-0625-47e1-ba1a-31943e43786a',
    balance: '$3,429.68',
    file_name: 'PETIGEMS',
    file_url: 'http://placehold.it/32x32',
    amount: '784636467 XAF',
    open_date: '12-02-09 10:00:00',
    state: 0,
    close_date: '',
    type_depot: 'Virement',
    moyen_paiement: 'AFRILAND FIRST BANK',
    account_debite: 'HJ67HQSDHA9831JH',
    account_credite: 'AIUEQDQDAIQDOOIP',
  },
  {
    id: 23,
    num: 924535165,
    reference: '01f5344a-b07c-48fc-9c8a-37bb5f64daa1',
    balance: '$3,966.85',
    file_name: 'IDEGO',
    file_url: 'http://placehold.it/32x32',
    amount: '864400972 XAF',
    open_date: '12-02-09 10:00:00',
    state: 0,
    close_date: '',
    type_depot: 'Virement',
    moyen_paiement: 'AFRILAND FIRST BANK',
    account_debite: 'HJ67HQSDHA9831JH',
    account_credite: 'AIUEQDQDAIQDOOIP',
  },
  {
    id: 24,
    num: 363024573,
    reference: 'e01491c4-e2c9-499f-98e2-1251cc9fd9fb',
    balance: '$1,743.36',
    file_name: 'ENTHAZE',
    file_url: 'http://placehold.it/32x32',
    amount: '61822879 XAF',
    open_date: '12-02-09 10:00:00',
    state: 1,
    close_date: '22/09/2022 10:10:00',
    type_depot: 'Versement',
    moyen_paiement: 'Orange Money',
    account_debite: '',
    account_credite: '',
  },
  {
    id: 25,
    num: 772578707,
    reference: '9448fdf3-d967-475f-8500-21fa39496683',
    balance: '$2,089.66',
    file_name: 'INCUBUS',
    file_url: 'http://placehold.it/32x32',
    amount: '188426437 XAF',
    open_date: '12-02-09 10:00:00',
    state: 1,
    close_date: '22/09/2022 10:10:00',
    type_depot: 'Virement',
    moyen_paiement: 'AFRILAND FIRST BANK',
    account_debite: 'HJ67HQSDHA9831JH',
    account_credite: 'AIUEQDQDAIQDOOIP',
  },
  {
    id: 26,
    num: 896113508,
    reference: '2bee6f21-6172-4b7f-a50e-91f07457d9d4',
    balance: '$3,414.45',
    file_name: 'JOVIOLD',
    file_url: 'http://placehold.it/32x32',
    amount: '263884095 XAF',
    open_date: '12-02-09 10:00:00',
    state: 0,
    close_date: '',
    type_depot: 'Virement',
    moyen_paiement: 'AFRILAND FIRST BANK',
    account_debite: 'HJ67HQSDHA9831JH',
    account_credite: 'AIUEQDQDAIQDOOIP',
  },
  {
    id: 27,
    num: 816047040,
    reference: 'bdbe56f2-e122-4a1f-8564-a23cdc5a916f',
    balance: '$3,347.75',
    file_name: 'ZYTREK',
    file_url: 'http://placehold.it/32x32',
    amount: '204857396 XAF',
    open_date: '12-02-09 10:00:00',
    state: 0,
    close_date: '',
    type_depot: 'Virement',
    moyen_paiement: 'AFRILAND FIRST BANK',
    account_debite: 'HJ67HQSDHA9831JH',
    account_credite: 'AIUEQDQDAIQDOOIP',
  },
  {
    id: 28,
    num: 539161755,
    reference: '718c8490-e0f4-4f71-a36d-1607d283bdd3',
    balance: '$1,276.91',
    file_name: 'EMERGENT',
    file_url: 'http://placehold.it/32x32',
    amount: '414035983 XAF',
    open_date: '12-02-09 10:00:00',
    state: 0,
    close_date: '',
    type_depot: 'Versement',
    moyen_paiement: 'Orange Money',
    account_debite: '',
    account_credite: '',
  },
  {
    id: 29,
    num: 803543332,
    reference: '152e9518-bcae-42ec-ab8a-78eabc3390dc',
    balance: '$3,738.48',
    file_name: 'ORONOKO',
    file_url: 'http://placehold.it/32x32',
    amount: '980998986 XAF',
    open_date: '12-02-09 10:00:00',
    state: 0,
    close_date: '',
    type_depot: 'Versement',
    moyen_paiement: 'Orange Money',
    account_debite: '',
    account_credite: '',
  },
  {
    id: 30,
    num: 142326433,
    reference: 'f830995a-f806-42cc-b631-266e5ff2d211',
    balance: '$2,908.84',
    file_name: 'BISBA',
    file_url: 'http://placehold.it/32x32',
    amount: '474360338 XAF',
    open_date: '12-02-09 10:00:00',
    state: 0,
    close_date: '',
    type_depot: 'Versement',
    moyen_paiement: 'Orange Money',
    account_debite: '',
    account_credite: '',
  },
  {
    id: 31,
    num: 680438694,
    reference: '3045ec3e-5fdc-462d-869e-99cab4094641',
    balance: '$1,289.91',
    file_name: 'INTERFIND',
    file_url: 'http://placehold.it/32x32',
    amount: '314697322 XAF',
    open_date: '12-02-09 10:00:00',
    state: 0,
    close_date: '',
    type_depot: 'Virement',
    moyen_paiement: 'AFRILAND FIRST BANK',
    account_debite: 'HJ67HQSDHA9831JH',
    account_credite: 'AIUEQDQDAIQDOOIP',
  },
  {
    id: 32,
    num: 214946162,
    reference: 'e0207b9b-eb8a-4810-9dfa-895c3bc91393',
    balance: '$2,609.86',
    file_name: 'UNIWORLD',
    file_url: 'http://placehold.it/32x32',
    amount: '629469779 XAF',
    open_date: '12-02-09 10:00:00',
    state: 0,
    close_date: '',
    type_depot: 'Virement',
    moyen_paiement: 'AFRILAND FIRST BANK',
    account_debite: 'HJ67HQSDHA9831JH',
    account_credite: 'AIUEQDQDAIQDOOIP',
  },
  {
    id: 33,
    num: 577589022,
    reference: '5c887180-50a2-491b-bb53-b1e47d189857',
    balance: '$2,393.34',
    file_name: 'ELENTRIX',
    file_url: 'http://placehold.it/32x32',
    amount: '62203572 XAF',
    open_date: '12-02-09 10:00:00',
    state: 0,
    close_date: '',
    type_depot: 'Versement',
    moyen_paiement: 'Orange Money',
    account_debite: '',
    account_credite: '',
  },
  {
    id: 34,
    num: 475269215,
    reference: '99635f46-8a3d-4592-8b1a-f2089a9d883f',
    balance: '$3,959.53',
    file_name: 'SNORUS',
    file_url: 'http://placehold.it/32x32',
    amount: '891834972 XAF',
    open_date: '12-02-09 10:00:00',
    state: 0,
    close_date: '',
    type_depot: 'Versement',
    moyen_paiement: 'Orange Money',
    account_debite: '',
    account_credite: '',
  },
  {
    id: 35,
    num: 776025962,
    reference: '67a9c8b4-77fb-4483-ab9c-fdc08b6f1d38',
    balance: '$1,582.50',
    file_name: 'MICRONAUT',
    file_url: 'http://placehold.it/32x32',
    amount: '894266723 XAF',
    open_date: '12-02-09 10:00:00',
    state: 0,
    close_date: '',
    type_depot: 'Versement',
    moyen_paiement: 'Orange Money',
    account_debite: '',
    account_credite: '',
  },
  {
    id: 36,
    num: 833543022,
    reference: '3ff910e5-5665-4157-b5f3-f49fc3b14ae2',
    balance: '$2,189.41',
    file_name: 'ZENOLUX',
    file_url: 'http://placehold.it/32x32',
    amount: '281783879 XAF',
    open_date: '12-02-09 10:00:00',
    state: 1,
    close_date: '22/09/2022 10:10:00',
    type_depot: 'Virement',
    moyen_paiement: 'AFRILAND FIRST BANK',
    account_debite: 'HJ67HQSDHA9831JH',
    account_credite: 'AIUEQDQDAIQDOOIP',
  },
  {
    id: 37,
    num: 977003248,
    reference: '3a001fde-92b6-4620-90c0-29fef765a491',
    balance: '$3,482.14',
    file_name: 'MAXIMIND',
    file_url: 'http://placehold.it/32x32',
    amount: '336594089 XAF',
    open_date: '12-02-09 10:00:00',
    state: 1,
    close_date: '22/09/2022 10:10:00',
    type_depot: 'Versement',
    moyen_paiement: 'Orange Money',
    account_debite: '',
    account_credite: '',
  },
  {
    id: 38,
    num: 807535924,
    reference: 'b11245ab-3ea1-4523-9f58-a83b907921a4',
    balance: '$1,893.13',
    file_name: 'KINDALOO',
    file_url: 'http://placehold.it/32x32',
    amount: '509285902 XAF',
    open_date: '12-02-09 10:00:00',
    state: 1,
    close_date: '22/09/2022 10:10:00',
    type_depot: 'Virement',
    moyen_paiement: 'AFRILAND FIRST BANK',
    account_debite: 'HJ67HQSDHA9831JH',
    account_credite: 'AIUEQDQDAIQDOOIP',
  },
  {
    id: 39,
    num: 922757713,
    reference: 'ae6431ac-f303-4624-8f7b-b2c98afa3fad',
    balance: '$1,318.70',
    file_name: 'ANOCHA',
    file_url: 'http://placehold.it/32x32',
    amount: '236210832 XAF',
    open_date: '12-02-09 10:00:00',
    state: 0,
    close_date: '',
    type_depot: 'Versement',
    moyen_paiement: 'Orange Money',
    account_debite: '',
    account_credite: '',
  },
  {
    id: 40,
    num: 221795878,
    reference: '41a98489-b2da-4957-9ff9-63d192aef842',
    balance: '$3,123.23',
    file_name: 'SEALOUD',
    file_url: 'http://placehold.it/32x32',
    amount: '343507037 XAF',
    open_date: '12-02-09 10:00:00',
    state: 1,
    close_date: '22/09/2022 10:10:00',
    type_depot: 'Versement',
    moyen_paiement: 'Orange Money',
    account_debite: '',
    account_credite: '',
  },
  {
    id: 41,
    num: 415692509,
    reference: '42a223d9-32ad-40cc-97e4-7fa4e8c81ccc',
    balance: '$1,120.17',
    file_name: 'VORTEXACO',
    file_url: 'http://placehold.it/32x32',
    amount: '682772108 XAF',
    open_date: '12-02-09 10:00:00',
    state: 1,
    close_date: '22/09/2022 10:10:00',
    type_depot: 'Versement',
    moyen_paiement: 'Orange Money',
    account_debite: '',
    account_credite: '',
  },
  {
    id: 42,
    num: 819552533,
    reference: 'bf2d7d1f-3f84-4ea7-8d20-0c3b032fb74f',
    balance: '$3,253.00',
    file_name: 'QIAO',
    file_url: 'http://placehold.it/32x32',
    amount: '274338748 XAF',
    open_date: '12-02-09 10:00:00',
    state: 0,
    close_date: '',
    type_depot: 'Virement',
    moyen_paiement: 'AFRILAND FIRST BANK',
    account_debite: 'HJ67HQSDHA9831JH',
    account_credite: 'AIUEQDQDAIQDOOIP',
  },
  {
    id: 43,
    num: 659090127,
    reference: 'b2599a4e-c2db-4e6f-a1b1-08f2de5c952b',
    balance: '$1,344.94',
    file_name: 'MEDMEX',
    file_url: 'http://placehold.it/32x32',
    amount: '456257343 XAF',
    open_date: '12-02-09 10:00:00',
    state: 1,
    close_date: '22/09/2022 10:10:00',
    type_depot: 'Versement',
    moyen_paiement: 'Orange Money',
    account_debite: '',
    account_credite: '',
  },
  {
    id: 44,
    num: 887937130,
    reference: 'd881da29-ab8e-400c-abc3-ae431f5355af',
    balance: '$3,542.86',
    file_name: 'PROTODYNE',
    file_url: 'http://placehold.it/32x32',
    amount: '395964815 XAF',
    open_date: '12-02-09 10:00:00',
    state: 0,
    close_date: '',
    type_depot: 'Virement',
    moyen_paiement: 'AFRILAND FIRST BANK',
    account_debite: 'HJ67HQSDHA9831JH',
    account_credite: 'AIUEQDQDAIQDOOIP',
  },
  {
    id: 45,
    num: 230703966,
    reference: '8a3b4e4e-e2b2-4bf3-b088-6cc3f5550362',
    balance: '$2,902.09',
    file_name: 'ISOLOGICA',
    file_url: 'http://placehold.it/32x32',
    amount: '746442401 XAF',
    open_date: '12-02-09 10:00:00',
    state: 1,
    close_date: '22/09/2022 10:10:00',
    type_depot: 'Virement',
    moyen_paiement: 'AFRILAND FIRST BANK',
    account_debite: 'HJ67HQSDHA9831JH',
    account_credite: 'AIUEQDQDAIQDOOIP',
  },
  {
    id: 46,
    num: 407867167,
    reference: 'afd92fa4-429f-4e3e-a88a-0a1b2b7b0245',
    balance: '$1,652.00',
    file_name: 'ESSENSIA',
    file_url: 'http://placehold.it/32x32',
    amount: '189099830 XAF',
    open_date: '12-02-09 10:00:00',
    state: 0,
    close_date: '',
    type_depot: 'Versement',
    moyen_paiement: 'Orange Money',
    account_debite: '',
    account_credite: '',
  },
  {
    id: 47,
    num: 524250526,
    reference: '99853e9b-5ee3-4836-86c5-85db3b034c2d',
    balance: '$2,535.64',
    file_name: 'ACCUSAGE',
    file_url: 'http://placehold.it/32x32',
    amount: '533045977 XAF',
    open_date: '12-02-09 10:00:00',
    state: 1,
    close_date: '22/09/2022 10:10:00',
    type_depot: 'Virement',
    moyen_paiement: 'AFRILAND FIRST BANK',
    account_debite: 'HJ67HQSDHA9831JH',
    account_credite: 'AIUEQDQDAIQDOOIP',
  },
  {
    id: 48,
    num: 649599493,
    reference: '21569656-7a64-42fa-85cb-1a8a9a09d40e',
    balance: '$1,325.59',
    file_name: 'KOFFEE',
    file_url: 'http://placehold.it/32x32',
    amount: '274371436 XAF',
    open_date: '12-02-09 10:00:00',
    state: 0,
    close_date: '',
    type_depot: 'Virement',
    moyen_paiement: 'AFRILAND FIRST BANK',
    account_debite: 'HJ67HQSDHA9831JH',
    account_credite: 'AIUEQDQDAIQDOOIP',
  },
  {
    id: 49,
    num: 104312867,
    reference: 'dc7d0ff3-ed8a-460e-85d9-85bdde4cf93b',
    balance: '$2,967.84',
    file_name: 'PROFLEX',
    file_url: 'http://placehold.it/32x32',
    amount: '327206032 XAF',
    open_date: '12-02-09 10:00:00',
    state: 1,
    close_date: '22/09/2022 10:10:00',
    type_depot: 'Virement',
    moyen_paiement: 'AFRILAND FIRST BANK',
    account_debite: 'HJ67HQSDHA9831JH',
    account_credite: 'AIUEQDQDAIQDOOIP',
  },
  {
    id: 50,
    num: 222022114,
    reference: 'a240b89e-1d0d-4c43-913b-0eed0f262a16',
    balance: '$1,870.94',
    file_name: 'MEMORA',
    file_url: 'http://placehold.it/32x32',
    amount: '770585816 XAF',
    open_date: '12-02-09 10:00:00',
    state: 1,
    close_date: '22/09/2022 10:10:00',
    type_depot: 'Virement',
    moyen_paiement: 'AFRILAND FIRST BANK',
    account_debite: 'HJ67HQSDHA9831JH',
    account_credite: 'AIUEQDQDAIQDOOIP',
  },
  {
    id: 51,
    num: 704691193,
    reference: '1813b19f-2296-4d92-9ef7-0a6a2cd0f0e4',
    balance: '$3,437.91',
    file_name: 'PURIA',
    file_url: 'http://placehold.it/32x32',
    amount: '161361980 XAF',
    open_date: '12-02-09 10:00:00',
    state: 0,
    close_date: '',
    type_depot: 'Virement',
    moyen_paiement: 'AFRILAND FIRST BANK',
    account_debite: 'HJ67HQSDHA9831JH',
    account_credite: 'AIUEQDQDAIQDOOIP',
  },
  {
    id: 52,
    num: 757334729,
    reference: '2dfc7c9f-bb83-4136-a7e0-b38860a6b3f0',
    balance: '$1,514.70',
    file_name: 'MAGNEATO',
    file_url: 'http://placehold.it/32x32',
    amount: '473688794 XAF',
    open_date: '12-02-09 10:00:00',
    state: 0,
    close_date: '',
    type_depot: 'Versement',
    moyen_paiement: 'Orange Money',
    account_debite: '',
    account_credite: '',
  },
  {
    id: 53,
    num: 746386274,
    reference: '2c14cf29-da43-4be4-b272-23fdcfbe35d3',
    balance: '$2,430.10',
    file_name: 'HOPELI',
    file_url: 'http://placehold.it/32x32',
    amount: '608025262 XAF',
    open_date: '12-02-09 10:00:00',
    state: 1,
    close_date: '22/09/2022 10:10:00',
    type_depot: 'Versement',
    moyen_paiement: 'Orange Money',
    account_debite: '',
    account_credite: '',
  },
  {
    id: 54,
    num: 68797634,
    reference: '4ebf544e-0323-4ba3-bf2a-9740247de9f1',
    balance: '$3,354.18',
    file_name: 'COMVOY',
    file_url: 'http://placehold.it/32x32',
    amount: '778368020 XAF',
    open_date: '12-02-09 10:00:00',
    state: 1,
    close_date: '22/09/2022 10:10:00',
    type_depot: 'Versement',
    moyen_paiement: 'Orange Money',
    account_debite: '',
    account_credite: '',
  },
  {
    id: 55,
    num: 198695337,
    reference: '04ba4a0e-61dc-4e4d-acd1-98b08ad8b5c7',
    balance: '$3,915.33',
    file_name: 'MANUFACT',
    file_url: 'http://placehold.it/32x32',
    amount: '818181372 XAF',
    open_date: '12-02-09 10:00:00',
    state: 0,
    close_date: '',
    type_depot: 'Virement',
    moyen_paiement: 'AFRILAND FIRST BANK',
    account_debite: 'HJ67HQSDHA9831JH',
    account_credite: 'AIUEQDQDAIQDOOIP',
  },
  {
    id: 56,
    num: 746540996,
    reference: 'c79dca9c-001d-4650-b5cb-c0eedb234370',
    balance: '$1,961.37',
    file_name: 'FURNIGEER',
    file_url: 'http://placehold.it/32x32',
    amount: '710326132 XAF',
    open_date: '12-02-09 10:00:00',
    state: 0,
    close_date: '',
    type_depot: 'Versement',
    moyen_paiement: 'Orange Money',
    account_debite: '',
    account_credite: '',
  },
  {
    id: 57,
    num: 47393530,
    reference: '7099c31b-b15a-4d77-b7d2-720af67c9d12',
    balance: '$3,072.97',
    file_name: 'ZEROLOGY',
    file_url: 'http://placehold.it/32x32',
    amount: '272947763 XAF',
    open_date: '12-02-09 10:00:00',
    state: 1,
    close_date: '22/09/2022 10:10:00',
    type_depot: 'Versement',
    moyen_paiement: 'Orange Money',
    account_debite: '',
    account_credite: '',
  },
  {
    id: 58,
    num: 543570711,
    reference: '35fbafba-eb33-45ba-8e66-236e1e5ffe39',
    balance: '$2,368.14',
    file_name: 'ZILLACTIC',
    file_url: 'http://placehold.it/32x32',
    amount: '383910283 XAF',
    open_date: '12-02-09 10:00:00',
    state: 0,
    close_date: '',
    type_depot: 'Versement',
    moyen_paiement: 'Orange Money',
    account_debite: '',
    account_credite: '',
  },
  {
    id: 59,
    num: 86535209,
    reference: 'db9d0978-f8b6-4f8a-ae5c-ce5d6967b78d',
    balance: '$3,207.76',
    file_name: 'BULLZONE',
    file_url: 'http://placehold.it/32x32',
    amount: '57285973 XAF',
    open_date: '12-02-09 10:00:00',
    state: 0,
    close_date: '',
    type_depot: 'Virement',
    moyen_paiement: 'AFRILAND FIRST BANK',
    account_debite: 'HJ67HQSDHA9831JH',
    account_credite: 'AIUEQDQDAIQDOOIP',
  },
  {
    id: 60,
    num: 23785507,
    reference: '62bf712c-8ad3-451d-aa34-96f79c756b69',
    balance: '$1,013.68',
    file_name: 'QUANTASIS',
    file_url: 'http://placehold.it/32x32',
    amount: '229893785 XAF',
    open_date: '12-02-09 10:00:00',
    state: 0,
    close_date: '',
    type_depot: 'Virement',
    moyen_paiement: 'AFRILAND FIRST BANK',
    account_debite: 'HJ67HQSDHA9831JH',
    account_credite: 'AIUEQDQDAIQDOOIP',
  },
  {
    id: 61,
    num: 30700499,
    reference: 'c363f702-bc54-4258-9444-a9e6c9996e04',
    balance: '$3,241.45',
    file_name: 'QUILM',
    file_url: 'http://placehold.it/32x32',
    amount: '864039764 XAF',
    open_date: '12-02-09 10:00:00',
    state: 0,
    close_date: '',
    type_depot: 'Versement',
    moyen_paiement: 'Orange Money',
    account_debite: '',
    account_credite: '',
  },
  {
    id: 62,
    num: 835866112,
    reference: '16a0b01a-3c04-47d3-b4e8-9b5e1ffaa421',
    balance: '$1,891.38',
    file_name: 'CALCU',
    file_url: 'http://placehold.it/32x32',
    amount: '435980414 XAF',
    open_date: '12-02-09 10:00:00',
    state: 1,
    close_date: '22/09/2022 10:10:00',
    type_depot: 'Versement',
    moyen_paiement: 'Orange Money',
    account_debite: '',
    account_credite: '',
  },
  {
    id: 63,
    num: 105400838,
    reference: '7c5b178b-071a-4ecf-acc4-b79447e82fde',
    balance: '$2,652.71',
    file_name: 'ORBIXTAR',
    file_url: 'http://placehold.it/32x32',
    amount: '120780639 XAF',
    open_date: '12-02-09 10:00:00',
    state: 0,
    close_date: '',
    type_depot: 'Versement',
    moyen_paiement: 'Orange Money',
    account_debite: '',
    account_credite: '',
  },
  {
    id: 64,
    num: 703528293,
    reference: 'd9d8fb34-0057-4bf5-8cdd-feab48eb126f',
    balance: '$2,147.80',
    file_name: 'MOMENTIA',
    file_url: 'http://placehold.it/32x32',
    amount: '581759828 XAF',
    open_date: '12-02-09 10:00:00',
    state: 1,
    close_date: '22/09/2022 10:10:00',
    type_depot: 'Virement',
    moyen_paiement: 'AFRILAND FIRST BANK',
    account_debite: 'HJ67HQSDHA9831JH',
    account_credite: 'AIUEQDQDAIQDOOIP',
  },
  {
    id: 65,
    num: 652631881,
    reference: '158c7052-7014-4d63-88df-4fa98fcdac75',
    balance: '$1,223.03',
    file_name: 'COMVEYOR',
    file_url: 'http://placehold.it/32x32',
    amount: '918516901 XAF',
    open_date: '12-02-09 10:00:00',
    state: 0,
    close_date: '',
    type_depot: 'Versement',
    moyen_paiement: 'Orange Money',
    account_debite: '',
    account_credite: '',
  },
  {
    id: 66,
    num: 975048025,
    reference: '68f19b0f-ad5a-4485-a4fe-a46af3202df8',
    balance: '$2,514.35',
    file_name: 'RODEOLOGY',
    file_url: 'http://placehold.it/32x32',
    amount: '426625323 XAF',
    open_date: '12-02-09 10:00:00',
    state: 1,
    close_date: '22/09/2022 10:10:00',
    type_depot: 'Virement',
    moyen_paiement: 'AFRILAND FIRST BANK',
    account_debite: 'HJ67HQSDHA9831JH',
    account_credite: 'AIUEQDQDAIQDOOIP',
  },
  {
    id: 67,
    num: 165416177,
    reference: '31f9d855-77fc-412c-b91a-8f43b843b158',
    balance: '$1,329.29',
    file_name: 'TALKALOT',
    file_url: 'http://placehold.it/32x32',
    amount: '509421036 XAF',
    open_date: '12-02-09 10:00:00',
    state: 1,
    close_date: '22/09/2022 10:10:00',
    type_depot: 'Versement',
    moyen_paiement: 'Orange Money',
    account_debite: '',
    account_credite: '',
  },
  {
    id: 68,
    num: 611423550,
    reference: 'fa8a33e9-40ad-43da-af5e-3b8749952ad1',
    balance: '$1,190.38',
    file_name: 'MEDALERT',
    file_url: 'http://placehold.it/32x32',
    amount: '250011920 XAF',
    open_date: '12-02-09 10:00:00',
    state: 1,
    close_date: '22/09/2022 10:10:00',
    type_depot: 'Virement',
    moyen_paiement: 'AFRILAND FIRST BANK',
    account_debite: 'HJ67HQSDHA9831JH',
    account_credite: 'AIUEQDQDAIQDOOIP',
  },
  {
    id: 69,
    num: 396230823,
    reference: 'f74473f7-d250-4625-9c21-c7c089e60dbb',
    balance: '$3,236.86',
    file_name: 'OPTYK',
    file_url: 'http://placehold.it/32x32',
    amount: '592555867 XAF',
    open_date: '12-02-09 10:00:00',
    state: 0,
    close_date: '',
    type_depot: 'Virement',
    moyen_paiement: 'AFRILAND FIRST BANK',
    account_debite: 'HJ67HQSDHA9831JH',
    account_credite: 'AIUEQDQDAIQDOOIP',
  },
  {
    id: 70,
    num: 730224600,
    reference: '40a77397-a826-450f-b293-1927615a84f7',
    balance: '$2,570.10',
    file_name: 'CUBICIDE',
    file_url: 'http://placehold.it/32x32',
    amount: '930143089 XAF',
    open_date: '12-02-09 10:00:00',
    state: 0,
    close_date: '',
    type_depot: 'Versement',
    moyen_paiement: 'Orange Money',
    account_debite: '',
    account_credite: '',
  },
  {
    id: 71,
    num: 176390875,
    reference: '277bf938-1e5c-41f2-86dd-340e03651025',
    balance: '$3,750.90',
    file_name: 'BITENDREX',
    file_url: 'http://placehold.it/32x32',
    amount: '897376955 XAF',
    open_date: '12-02-09 10:00:00',
    state: 0,
    close_date: '',
    type_depot: 'Versement',
    moyen_paiement: 'Orange Money',
    account_debite: '',
    account_credite: '',
  },
  {
    id: 72,
    num: 982596166,
    reference: '66a49a4c-1584-4488-849f-428099b2b8f7',
    balance: '$1,593.31',
    file_name: 'FLEETMIX',
    file_url: 'http://placehold.it/32x32',
    amount: '160508063 XAF',
    open_date: '12-02-09 10:00:00',
    state: 1,
    close_date: '22/09/2022 10:10:00',
    type_depot: 'Virement',
    moyen_paiement: 'AFRILAND FIRST BANK',
    account_debite: 'HJ67HQSDHA9831JH',
    account_credite: 'AIUEQDQDAIQDOOIP',
  },
  {
    id: 73,
    num: 635364767,
    reference: '7bc35b5b-8841-4050-864a-0bb97847abc6',
    balance: '$1,514.61',
    file_name: 'GLOBOIL',
    file_url: 'http://placehold.it/32x32',
    amount: '168758730 XAF',
    open_date: '12-02-09 10:00:00',
    state: 0,
    close_date: '',
    type_depot: 'Virement',
    moyen_paiement: 'AFRILAND FIRST BANK',
    account_debite: 'HJ67HQSDHA9831JH',
    account_credite: 'AIUEQDQDAIQDOOIP',
  },
  {
    id: 74,
    num: 38077288,
    reference: 'b03a4b4a-dc17-4c00-b741-a51501b7d266',
    balance: '$3,719.07',
    file_name: 'BIZMATIC',
    file_url: 'http://placehold.it/32x32',
    amount: '693781799 XAF',
    open_date: '12-02-09 10:00:00',
    state: 1,
    close_date: '22/09/2022 10:10:00',
    type_depot: 'Versement',
    moyen_paiement: 'Orange Money',
    account_debite: '',
    account_credite: '',
  },
  {
    id: 75,
    num: 197031059,
    reference: '040fe4eb-55e3-436b-8bb2-04d4760d6b3b',
    balance: '$3,800.60',
    file_name: 'VENDBLEND',
    file_url: 'http://placehold.it/32x32',
    amount: '164396956 XAF',
    open_date: '12-02-09 10:00:00',
    state: 1,
    close_date: '22/09/2022 10:10:00',
    type_depot: 'Versement',
    moyen_paiement: 'Orange Money',
    account_debite: '',
    account_credite: '',
  },
  {
    id: 76,
    num: 543839549,
    reference: '2c9ffc38-6676-45dc-bf52-3083a9b4dd8f',
    balance: '$1,323.69',
    file_name: 'BITREX',
    file_url: 'http://placehold.it/32x32',
    amount: '242070551 XAF',
    open_date: '12-02-09 10:00:00',
    state: 1,
    close_date: '22/09/2022 10:10:00',
    type_depot: 'Virement',
    moyen_paiement: 'AFRILAND FIRST BANK',
    account_debite: 'HJ67HQSDHA9831JH',
    account_credite: 'AIUEQDQDAIQDOOIP',
  },
  {
    id: 77,
    num: 163122445,
    reference: '5db19641-e478-4176-a453-78db591d221d',
    balance: '$2,925.26',
    file_name: 'VISUALIX',
    file_url: 'http://placehold.it/32x32',
    amount: '442362490 XAF',
    open_date: '12-02-09 10:00:00',
    state: 0,
    close_date: '',
    type_depot: 'Versement',
    moyen_paiement: 'Orange Money',
    account_debite: '',
    account_credite: '',
  },
]

export default data
